import {
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";
import { ethers } from "ethers";
import { tokens, USDC_CONTRACT_ADDRESS } from "./constants";
import ERC20ABI from "../abis/ERC20.json";
import { RPC_URLS } from "./rpcUrls";
import axios from "axios";

const headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Authorization", `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`);

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const sendError = async (error) => {
  const result = await fetch(`${BASE_URL}/error/add`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(error),
  });

  const resultJson = await result.json();

  console.log("Error Save Result: ", JSON.stringify(resultJson, null, 2));
  return resultJson;
};

export const getUser = async (username) => {
  const resp = await fetch(`${BASE_URL}/user?username=${username}`, {
    method: "GET",
    headers: headers,
  });

  return await resp.json();
};

export const getWalletAddressForUsername = async (username) => {
  const resp = await fetch(
    `${BASE_URL}/api/user/get-address-for-username/?username=${username}`,
    {
      method: "GET",
      headers: headers,
    }
  );

  return await resp.json();
};

export const getUsernameForWalletAddress = async (walletAddress) => {
  const resp = await fetch(
    `${BASE_URL}/api/user/get-username-for-address/?wallet_address=${walletAddress}`,
    {
      method: "GET",
      headers: headers,
    }
  );

  return await resp.json();
};

export const getBalancesForUser = async (username) => {
  const resp = await fetch(`${BASE_URL}/api/balances?username=${username}`, {
    method: "GET",
    headers: headers,
  });

  return (await resp.json()).data;
};

export const createAccount = async (username) => {
  const resp = await fetch(
    `${BASE_URL}/v2/webauthn/register/generate-options?username=${username}`,
    {
      method: "GET",
      headers: headers,
    }
  );

  let attResp;
  try {
    const opts = await resp.json();
    console.log("Resitration Options: ", opts);
    attResp = await startRegistration(opts);
  } catch (error) {
    throw error;
  }

  console.log("\n\nattResp: ", attResp);

  const verificationResp = await fetch(
    `${BASE_URL}/v2/webauthn/register/verify?username=${username}`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(attResp),
    }
  );

  const verificationJSON = await verificationResp.json();

  console.log("verificationJSON: ", JSON.stringify(verificationJSON, null, 2));
  return verificationJSON;
};

export const signInAccount = async (username) => {
  const resp = await fetch(
    `${BASE_URL}/webauthn/authenticate/generate-options?username=${username}`,
    {
      method: "GET",
      headers: headers,
    }
  );

  let attResp;
  try {
    const opts = await resp.json();
    console.log("Resitration Options: ", opts);
    attResp = await startAuthentication(opts);
  } catch (error) {
    throw error;
  }

  console.log("\n\nattResp: ", attResp);

  const verificationResp = await fetch(
    `${BASE_URL}/webauthn/authenticate/verify?username=${username}`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(attResp),
    }
  );

  const verificationJSON = await verificationResp.json();

  console.log("verificationJSON: ", JSON.stringify(verificationJSON, null, 2));
  return verificationJSON;
};

export const submitMultiTransaction = async (
  username,
  transactionDetails,
  network,
  gasMode,
  label = undefined,
  feeToken = undefined,
  feeTokenAmount = undefined
) => {
  console.log("submitTransaction: ", username, transactionDetails, network);

  const resp = await fetch(
    `${BASE_URL}/v2/webauthn/transaction/generate-options`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        username,
        transactionDetails,
        network,
        gasMode,
        label,
        feeToken,
        feeTokenAmount: feeTokenAmount?.toString(),
      }),
    }
  );

  let attResp;
  try {
    const opts = await resp.json();
    console.log("Resitration Options: ", opts);
    attResp = await startAuthentication(opts);
  } catch (error) {
    throw error;
  }

  console.log("\n\nattResp: ", attResp);

  const verificationResp = await fetch(
    `${BASE_URL}/v2/webauthn/transaction/verify?username=${username}`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(attResp),
    }
  );

  const verificationJSON = await verificationResp.json();

  console.log("verificationJSON: ", JSON.stringify(verificationJSON, null, 2));
  return verificationJSON;
};

export const getMultiGasFees = async (
  walletAddress,
  transactionDetails,
  network
) => {
  const resp = await axios.post(
    `${BASE_URL}/v2/gas-fees`,
    { walletAddress, transactionDetails, network },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`,
      },
    }
  );

  return resp.data;
};

export const checkUserName = async (username) => {
  const resp = await fetch(
    `${BASE_URL}/api/user/check-username?username=${username}`,
    {
      method: "GET",
      headers: headers,
    }
  );

  const result = await resp.json();
  return result;
};

export const checkInviteCode = async (inviteCode) => {
  const resp = await fetch(
    `${BASE_URL}/api/check-invite-code?inviteCode=${inviteCode}`,
    {
      method: "GET",
      headers: headers,
    }
  );

  return await resp.json();
};

export const updateGasConfig = async (username, gasConfig) => {
  const resp = await fetch(
    `${BASE_URL}/api/user/update-gas-config?username=${username}&mode=${gasConfig}`,
    {
      method: "PUT",
      headers: headers,
    }
  );

  return await resp.json();
};

export const getTransactionList = async (username) => {
  const resp = await axios.get(
    `${BASE_URL}/api/transactions/list?username=${username}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`,
      },
    }
  );

  return resp.data;
};

const provider = new ethers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
provider.polling = false;
provider.pollingInterval = 10000;

export const getERC20Balance = async (userAddress) => {
  const contract = new ethers.Contract(
    USDC_CONTRACT_ADDRESS,
    ERC20ABI,
    provider
  );
  const balance = await contract.balanceOf(userAddress);
  return ethers.formatUnits(balance, 6); // Assuming the token has 6 decimal places
};

export const getEtherBalance = async (userAddress) => {
  const balance = await provider.getBalance(userAddress);
  return ethers.formatEther(balance);
};

// Function to fetch transaction receipt
export async function fetchTransactionReceipt(txnHash, network) {
  try {
    const provider = new ethers.JsonRpcProvider(RPC_URLS[network]);
    let receipt = null;
    const maxAttempts = 12; // Maximum number of attempts
    let attempts = 0;

    while (attempts < maxAttempts) {
      receipt = await provider.getTransactionReceipt(txnHash);
      if (receipt) {
        return receipt; // Return the receipt if found
      }
      attempts++;
      await new Promise(resolve => setTimeout(resolve, 200)); // Wait for 2 seconds before retrying
    }

    console.error("Transaction receipt not found after maximum attempts.");
    return null; // Return null if not found after max attempts
  } catch (error) {
    console.error("Error fetching transaction receipt:", error);
  }
}

export const getMultiERC20Balance = async (walletDetails) => {
  const resultPromises = walletDetails.map(async (wallet) => {
    const provider = new ethers.JsonRpcProvider(RPC_URLS[wallet.network]);
    const contract = new ethers.Contract(
      tokens.USDC[wallet.network],
      ERC20ABI,
      provider
    );
    const balance = await contract.balanceOf(wallet.address);
    return {
      network: wallet.network,
      balance: ethers.formatUnits(balance, 6),
    };
  });

  return await Promise.all(resultPromises);
};

export const getMultiEtherBalance = async (walletDetails) => {
  const resultPromises = walletDetails.map(async (wallet) => {
    const provider = new ethers.JsonRpcProvider(RPC_URLS[wallet.network]);
    const balance = parseFloat(await provider.getBalance(wallet.address));
    return {
      network: wallet.network,
      balance: ethers.formatEther(balance.toString()),
    };
  });

  return await Promise.all(resultPromises);
};

export const updateUserData = async (username, fcmToken) => {
  console.log("In updateUserData", username, fcmToken);
  const resp = await axios.put(
    `${BASE_URL}/api/user/fcm-token`,
    { username, fcmToken },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`,
      },
    }
  );

  return resp.data;
};

export const getERC20TransferCallData = (
  amount,
  tokenAddress,
  toAddress,
  decimals
) => {
  const contract = new ethers.Contract(tokenAddress, ERC20ABI);
  const amountWei = ethers.parseUnits(amount.toString(), decimals);
  // Assuming 18 decimals, adjust if needed
  return contract.interface.encodeFunctionData("transfer", [
    toAddress,
    amountWei,
  ]);
};

export const processBalances = (balances) => {
  return balances.reduce((acc, balance) => {
    if (!acc[balance.symbol]) {
      acc[balance.symbol] = {
        symbol: balance.symbol,
        name: balance.name,
        decimals: balance.decimals,
        logoURI: balance.logoURI,
        icon: balance.icon,
        networks: {},
        total: 0,
        totalUsd: 0,
      };
    }
    acc[balance.symbol].total += balance.amount;
    acc[balance.symbol].totalUsd += balance.valueUsd;
    acc[balance.symbol].networks[balance.chainId] = {
      amount: balance.amount,
      address: balance.address,
    };
    return acc;
  }, {});
};

export const getAllTokenMap = (tokenList) => {
  return tokenList.reduce((map, token) => {
      map[token.symbol] = token;
      return map;
  }, {});
};

export const processBalances3 = (balances, tokenMap) => {
  // With balance price change
  return balances.reduce((acc, balance) => {
    if (balance.symbol && balance.symbol.length > 0 && tokenMap[balance.symbol]) {
      if (!acc[balance.symbol]) {
        acc[balance.symbol] = {
          symbol: balance.symbol,
          name: balance.name,
          decimals: balance.decimals,
          logoURI: balance.logoURI,
          icon: balance.icon,
          networks: {},
          total: 0,
          totalUsd: 0,
          deltaAbs: 0
        };
      }
      acc[balance.symbol].total += balance.amount;
      acc[balance.symbol].totalUsd += balance.valueUsd;
      acc[balance.symbol].deltaAbs = parseFloat(acc[balance.symbol].totalUsd) * tokenMap[balance.symbol].priceChange24h / 100
      acc[balance.symbol].networks[balance.chainId] = {
        amount: balance.amount,
        address: balance.address,
      };
    }
    return acc;
  }, {});
};

export const approveAddressERC20_CallData = (
  address,
  tokenAddress,
  network,
  amount
) => {
  const provider = new ethers.JsonRpcProvider(RPC_URLS[network]);
  const usdc = new ethers.Contract(tokenAddress, ERC20ABI, provider);

  // ABI encode approve function in ERC20 contract
  const encodedData = usdc.interface.encodeFunctionData("approve", [
    address,
    amount,
  ]);
  return encodedData;
};

export const getExplorerUrl = (chainId) => {
  switch (chainId) {
    case 1:
      return "https://etherscan.io";
    case 137:
      return "https://polygonscan.com";
    case 8453:
      return "https://basescan.org/tx/";
    case 10:
      return "https://optimistic.etherscan.io/tx/";
    case 56:
      return "https://bscscan.com/tx/";
    case 42161:
      return "https://arbiscan.io/tx/";
    case 43114:
      return "https://snowtrace.io/tx/";
    default:
      return `https://scope.sh/${chainId}/tx/`;
  }
};

export const updateTwitterMetadata = async (username, twitterUserId, twitterHandle) => {
  const resp = await axios.put(
    `${BASE_URL}/api/user/twitter-metadata`,
    { username, twitterUserId, twitterHandle },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_ENCLAVE_SDK_KEY}`,
      },
    }
  );

  return resp.data;
};
