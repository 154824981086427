export const CrossPasskey = () => {
    return (
        <div>
            {
                JSON.stringify({
                    "origins": [
                        "https://alpha.endeavour.fun",
                        "https://enclave.money",
                        "https://alpha.enclave.money",
                        "localhost",
                        "https://localhost:3002"
                    ]
                })
            }
        </div>
    )
}