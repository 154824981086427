import styles from "./css/bottomNav.module.css";

import { Link, useLocation } from "react-router-dom";
import { useEnclaveApi } from "./EnclaveSDK/context/EnclaveConnectProvider";
import { ChevronRight } from "lucide-react";
import { ChevronLeft, KeyboardArrowDown } from "@mui/icons-material";

export const TokenDetailsBottomNav = ({
  closeDrawer,
  onNext,
  onPrevious,
  hasNext = true,
  hasPrevious = true,
}) => {
  const location = useLocation();
  const { userData } = useEnclaveApi();

  const hapticFeedback = (label) => {
    if (navigator.vibrate) {
      navigator.vibrate(2);
    }
    switch (label) {
      case "close":
        closeDrawer();
        break;
      case "next":
        onNext();
        break;
      case "previous":
        onPrevious();
        break;
      default:
        break;
    }
  };

  const NAV_OPTIONS = [
    {
      label: "previous",
      icon: <ChevronLeft style={{ fontSize: "30px" }} />,
      disabled: !hasPrevious,
    },
    {
      label: "close",
      icon: <KeyboardArrowDown style={{ fontSize: "30px" }} />,
    },
    {
      label: "next",
      icon: <ChevronRight style={{ fontSize: "30px" }} />,
      disabled: !hasNext,
    },
  ];

  return (
    userData &&
    Object.entries(userData).length > 0 && (
      <>
        <div className={styles.bottomNavTokenDetailsContainer}>
          <div className={styles.navItemsContainer}>
            {NAV_OPTIONS.map((itm, i) => (
              <div
                key={i}
                className={`${styles.navListItem2} ${
                  location.pathname === itm.pathname ? styles.activeBlue : ""
                } ${itm.disabled ? styles.disabled : ""}`}
              >
                <div
                  onClick={() => !itm.disabled && hapticFeedback(itm.label)}
                  className={
                    location.pathname === itm.pathname
                      ? styles.activeIconWrapper
                      : styles.iconWrapper
                  }
                >
                  {itm.icon}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  );
};
