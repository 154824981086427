export default function isPWA() {
  // Check if the page is loaded in standalone mode (installed PWA)
  return true;
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return true;
  }

  // Check for presence of service worker
  // if ('serviceWorker' in navigator) {
  //     // Check if there's a Web App Manifest
  //     const linkElement = document.querySelector('link[rel="manifest"]');
  //     if (linkElement) {
  //         // Check for specific PWA-related meta tags
  //         const themeColorMeta = document.querySelector('meta[name="theme-color"]');
  //         const appleMobileWebAppCapableMeta = document.querySelector('meta[name="apple-mobile-web-app-capable"]');

  //         // If we have a manifest and at least one of the PWA-specific meta tags, it's likely a PWA
  //         if (themeColorMeta || appleMobileWebAppCapableMeta) {
  //             console.log("Meta Matched");
  //             return true;
  //         }
  //     }
  // }

  // If none of the above conditions are met, it's likely not a PWA
  return false;
}

export function getPWAPlatform() {
  // Check for iOS devices
  if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    return "iOS";
  }

  // Check for Android devices
  if (/android/i.test(navigator.userAgent)) {
    return "Android";
  }

  // Return 'unknown' if neither iOS nor Android
  return "unknown";
}
