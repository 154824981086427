import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import EnclaveConnectProvider from "./components/EnclaveSDK/context/EnclaveConnectProvider";
import { Sidebar } from "./components/sidebar";
import { Topbar } from "./components/topbar";
import EarnPage from "./components/pages/earn";
import SwapPage from "./components/pages/swap";
import DashboardPage from "./components/pages/dashboard";
import ActivityPage from "./components/pages/activity";
import YieldDetailsPage from "./components/pages/yieldDetails";
import ExplorePage from "./components/pages/explore";
import { BottomNav } from "./components/bottomNav";
import isPWA, { getPWAPlatform } from "./utils/pwaUtils";
import EndevourLogo from "./components/Endeavour1.png";

import ios1 from "./assets/ShareIOS.png";
import ios2 from "./assets/AddToHomeIOS.png";

import android1 from "./assets/AndroidMore.png";
import android2 from "./assets/AndroidAddToHome.png";

import TokenPage from "./components/pages/tokenDetails";
import DepositPage from "./components/pages/deposit";
import AccountPage from "./components/pages/account";
import BuyPage from "./components/pages/buy";
import TransferPage from "./components/pages/transfer";
import { motion, AnimatePresence } from "framer-motion";
import { get } from "lodash";
import { Toaster } from "react-hot-toast";
import QRCodeStyling from "qr-code-styling";
import Endevour from "./components/endeavour-192.png";
import { Auth0Provider } from "@auth0/auth0-react";

import { CrossPasskey } from "./components/pages/crossPasskey";

function App() {
  const location = useLocation();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  const [showDesktopPopover, setShowDesktopPopover] = useState(true);
  const qrRef = useRef(null);

  const [mobile, setMobile] = useState(false);
  const [android, setAndroid] = useState(false);

  useEffect(() => {
    const isAndroid = !!navigator.userAgent.match(/Android/i);
    setAndroid(isAndroid);

    const isMobile =
      isAndroid ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i);

    setMobile(isMobile);
  }, []);

  useEffect(() => {
    // Prevent pull-to-refresh behavior
    if (getPWAPlatform() === "iOS") {
      console.log(getPWAPlatform());
      document.body.style.overscrollBehavior = "none";
    }

    // Original beforeinstallprompt listener
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
      // Reset overscroll behavior on cleanup
      document.body.style.overscrollBehavior = "auto";
    };
  }, []);

  useEffect(() => {
    if (!mobile && showDesktopPopover) {
      const qrCode = new QRCodeStyling({
        width: 220,
        height: 220,
        data: "https://alpha.endeavour.fun/",
        image: Endevour,
        type: "svg",
        dotsOptions: {
          color: "#fcff61",
          type: "extra-rounded",
        },
        cornersSquareOptions: {
          type: "extra-rounded",
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 20,
        },
        backgroundOptions: {
          color: "transparent", // Set background to transparent
        },
      });

      if (qrRef.current) {
        qrRef.current.innerHTML = "";
        qrCode.append(qrRef.current);
      }
    }
  }, [mobile, showDesktopPopover]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt

      const { outcome } = await deferredPrompt.userChoice; // Wait for user response

      setDeferredPrompt(null); // Reset the deferredPrompt
      setIsInstallable(false); // Hide install button
    }
  };

  // if (!mobile && showDesktopPopover) {
  //   return (
  //     <div className="desktop-popover">
  //       <div className="popover-content">
  //         <img src={Endevour} width={40} alt="Endeavour" />
  //         <h2>Welcome to Endeavour</h2>
  //         <div ref={qrRef} />
  //         <p>Endeavour works best on your mobile device. </p>
  //         <span>
  //           Please visit{": "}
  //           <a href="https://alpha.endeavour.fun/">
  //             {" "}
  //             https://alpha.endeavour.fun
  //           </a>
  //           <p>on your phone and install the app.</p>
  //         </span>
  //       </div>
  //     </div>
  //   );
  // }

  return mobile && !isPWA() ? (
    <div className="app">
      <main>
        <div className="installPwa">
          <img src={EndevourLogo} width={200} alt="Endeavour" />
          <br />
          <br />
          {android ? (
            <button className="btn-primary">
              <h2 onClick={handleInstallClick}>Install App</h2>
            </button>
          ) : (
            <h2>Install App</h2>
          )}
          <br />
          <p>
            Enjoy faster load times and a smoother experience by installing the
            endeavour app to your home screen
          </p>
          <div className="divider"></div>
          {android ? (
            <div className="instructionContainer">
              <span>1. Click on the menu button</span>
              <img src={android1} width={300} />
              <br />
              <span>2. Click on "Add to home screen" in the menu</span>
              <img src={android2} width={300} />
            </div>
          ) : (
            <div className="instructionContainer">
              <span>1. Click on the share button</span>
              <img src={ios1} width={300} />
              <br />
              <span>2. Click on "Add to home screen" in the menu</span>
              <img src={ios2} width={300} />
            </div>
          )}
        </div>
      </main>
    </div>
  ) : (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_MANAGEMENT_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        // Add any additional parameters you need
      }}
    >
      <EnclaveConnectProvider>
        <div className="app">
          <Toaster position="top-center" /> {/* Add this line */}
          <Sidebar />
          <main>
            <Topbar />
            {/* <AnimatePresence mode="wait"> */}
            <Routes location={location} key={location.pathname}>
              {[
                { path: "/dashboard", element: <DashboardPage /> },
                { path: "/deposit", element: <DepositPage /> },
                { path: "/account", element: <AccountPage /> },
                { path: "/buy", element: <BuyPage /> },
                { path: "/earn", element: <EarnPage /> },
                { path: "/swap", element: <SwapPage /> },
                { path: "/", element: <ExplorePage /> },
                { path: "/explore", element: <ExplorePage /> },
                { path: "/activity", element: <ActivityPage /> },
                { path: "/yield", element: <YieldDetailsPage /> },
                { path: "/transfer", element: <TransferPage /> },
                { path: "/explore/token/:tokenSymbol", element: <TokenPage /> },
                { path: "/.well-known/webauthn", element: <CrossPasskey /> },
              ].map(({ path, element }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.1 }}
                    >
                      {element}
                    </motion.div>
                  }
                />
              ))}
            </Routes>
            {/* </AnimatePresence> */}
            {isPWA() && <BottomNav />}
          </main>
        </div>
      </EnclaveConnectProvider>
    </Auth0Provider>
  );
}

export default App;
